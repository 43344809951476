import {useAuthContext} from "../../../context/AuthContext";
import React from "react";
import {Login} from "../../index";
import isUndefined from 'lodash/isUndefined'
import Preloader from "../../common/preloader";
import {
  useLocation
} from "react-router-dom";
import {ROUTES} from "../../../config/constants";

type Props = {
    children: JSX.Element
}

const externalPages = [
  ROUTES.LOGIN,
  ROUTES.FORGOT_PASSWORD,
  `${ROUTES.RESET_PASSWORD}/:id`,
  ROUTES.REGISTER,
  ROUTES.WELCOME
];

const AccessWrapper = ({children} : Props) => {

  const { user, isSuccess, isNewUser } = useAuthContext();

  const existingUserNoData = isUndefined(user) && !isNewUser;

  const location = useLocation();

  const isExternalPage = externalPages.includes(location.pathname.replace(/\/$/, ""));

  if (!isSuccess || existingUserNoData) {
    return <Preloader />
  }

  return user || isExternalPage ? children : <Login/>

}

export default AccessWrapper;
