import React from "react";
import {Auth, ForgotPassword, Login, Register, ResetPassword, Welcome} from "./../../components";
import {ROUTES} from "../../config/constants";
import Main from "./../../components/cabinet/main";
import Calculator from "./../../components/cabinet/calculator";
import Calculations from "./../../components/cabinet/calculations";
import Settings from "./../../components/cabinet/settings";
import Dictionaries from "./../../components/cabinet/dictionaries/pages";
import Currency from "./../../components/cabinet/dictionaries/pages/currency";
import Locations from "./../../components/cabinet/dictionaries/pages/locations";
import Countries from "./../../components/cabinet/dictionaries/pages/countries";
import Cities from "./../../components/cabinet/dictionaries/pages/cities";
import Incoterms from "./../../components/cabinet/dictionaries/pages/incoterms";
import TransportCompanies from "./../../components/cabinet/dictionaries/pages/transport_companies";

export const ROUTES_DEFAULT_ARRAY = [
  {
    path: '/',
    element: <Auth />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:id`,
    element: <ResetPassword />,
  },
  // {
  //   path: ROUTES.REGISTER,
  //   element: <Register />,
  // },
  {
    path: ROUTES.WELCOME,
    element: <Welcome />,
  },
]

export const ROUTES_CABINET_ARRAY = [
  {
    path: '',
    element: <Main />,
  },
  {
    path: ROUTES.CALCULATOR,
    element: <Calculator />,
  },
  {
    path: `${ROUTES.CALCULATOR}/:id`,
    element: <Calculator />,
  },
  {
    path: ROUTES.CALCULATIONS,
    element: <Calculations />,
  },
  {
    path: ROUTES.SETTINGS,
    element: <Settings />,
  },
  {
    path: ROUTES.DICTIONARIES,
    element: <Dictionaries />,
  },
]

export const DICTIONARIES_CABINET_ARRAY = [
  {
    path: ROUTES.CURRENCY,
    element: <Currency />,
  },
  {
    path: ROUTES.LOCATIONS,
    element: <Locations />,
  },
  {
    path: ROUTES.COUNTRIES,
    element: <Countries />,
  },
  {
    path: ROUTES.CITIES,
    element: <Cities />,
  },
  {
    path: ROUTES.INCOTERMS,
    element: <Incoterms />,
  },
  {
    path: ROUTES.TRANSPORT_COMPANIES,
    element: <TransportCompanies />,
  },
  {
    path: '',
    element: <Currency />,
  },
]
