import React from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";

import {COLORS} from "../../../../../../../config/constants";
import {getPluralForm} from "../../../../../../../utils/validation";
import {Declaration} from "../../../../../../../assets/svg";

import './customsTitle.scss';

type Props = {
    declaration: DeclarationsParamsOutput
    index: number
}

const CustomsTitle = ({declaration, index} : Props) => {

  const { t } = useTranslation()

  const CODES_DECLENSIONS: PluralForms = [ t('declension__text__HSCodeOne'), t('declension__text__HSCodeSeveral'), t('declension__text__HSCodeMany') ];

  return (
    <div className='customs-title'>
      <div className='customs-title__left'>
        <Declaration color={declaration.is_completed ? COLORS.GREEN : COLORS.SLIGHTLY_GRAY}/>
        <div>
          <Typography variant='h6' className='customs-title__name'>
            {`${index + 1}. ${t('calc__text__customs__declaration')} №${index + 1}`}
          </Typography>
          <Typography variant='body2' sx={{color: COLORS.LIGHT_TEXT_DISABLED}}>
            {`${declaration.code_count} ${getPluralForm(declaration.code_count || 0, CODES_DECLENSIONS)}`}
          </Typography>
        </div>
      </div>
      <div className='customs-title__right'>
        <Typography variant='h6'>
          {declaration?.lowin_total ? `${declaration?.lowin_total} USD` : ''}
        </Typography>
      </div>
    </div>
  )
}

export default CustomsTitle
