import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from 'config/constants'
import {getToken} from "./auth";
import i18n from 'i18next';

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = getToken()
    const language = i18n.language || 'ru-RU';
    headers.set('authorization', `Bearer ${token}`)
    headers.set('Accept-Language', language)
    return headers
  }
})
